import { Link } from "react-router-dom";
import Wrapper from "../Components/Wrapper";
import Pagination from "../Components/Pagination";
import { useEffect, useState } from "react";
import moment from "moment";
import AuthService from "../services/auth";

const rowsPerPage = 10;

const Users = ({
  showSnackbar
}) => {

  const [page, set_page] = useState(0);
  const [count, set_count] = useState(0);
  const [data, set_data] = useState([]);

  useEffect(() => {
    getData();

    // eslint-disable-next-line
  }, [page])

  const getData = async () => {

    const skip = page * rowsPerPage;

    // eslint-disable-next-line
    const [res, error] = await AuthService.getUsers(skip, rowsPerPage);

    if (res) {
      set_data(res.data.data);
      set_count(res.data.total_records);
    }
  }

  const handleChangeStatus = async (id, checked) => {
    // eslint-disable-next-line
    const [res, error] = await AuthService.updateUser(id, { active : checked });

    if (error) {
      showSnackbar('Error while updating user status!', 'danger');
    }else{
      getData();
      showSnackbar('Status updated successfully !', 'success');
    }
  }

  return (
    <Wrapper
      showSnackbar={showSnackbar}
    >
      <div className="d-flex justify-content-between">
        <div className="paper-title font-semibold">
          Users
        </div>

        <Link to='/users/add' className="btn text-white background-primary">
          Add New
        </Link>
      </div>

      <div className="bg-white shadow-sm rounded-3 table-container overflow-hidden">
        <table className="table site-table align-middle mb-0">
          <thead>
            <tr>
              <th className="font-medium"> Name </th>
              <th className="font-medium"> Username </th>
              <th className="font-medium"> Email </th>
              <th className="font-medium"> Status </th>
              <th className="font-medium"> Created At </th>
            </tr>
          </thead>
          <tbody>

            {
              data.map((item) => {
                return (
                  <tr key={item._id}>
                    <td> {item.name} </td>
                    <td> {item.username} </td>
                    <td> {item.email} </td>
                    <td>
                      <div className="d-flex">
                        <div class="form-check form-switch shadow-none">
                          <input
                            class="form-check-input shadow-none"
                            type="checkbox"
                            role="switch"
                            checked={item.active}
                            onChange={(e) => handleChangeStatus(item._id, e.target.checked)}
                          />
                        </div>
                        {item.active ? 'Active' : 'Inactive'}
                      </div>
                    </td>
                    <td> {moment(item.created_at).format("DD-MM-YYYY")} </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
      </div>

      <Pagination
        page={page}
        onPageChange={(page_no) => set_page(page_no)}
        total={count}
        rowsPerPage={10}
      />
    </Wrapper>
  )
}

export default Users;