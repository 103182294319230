import { useState } from "react";
import AuthService from "../services/auth";
import { Link } from "react-router-dom";
import { ReactComponent as MantasLogo } from "../assets/images/logo.svg";

const ForgotPassword = ({ showSnackbar }) => {

  const [value, set_value] = useState("");
  const [is_saving, set_is_saving] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    set_is_saving(true);
    const [res] = await AuthService.sendResetPasswordMail({
      value : value
    });
    set_is_saving(false);

    if (res && res.data && res.data.success) {
      showSnackbar("Reset password link sent successfully !", 'success')
    } else {
      showSnackbar("User not found !", 'danger')
    }
  }

  return (
    <div className="login-container min-vh-100 d-flex align-items-center justify-content-center">
      <div className="login-form-container d-flex align-items-center">
        <form
          className="login-form"
          onSubmit={handleSubmit}
        >
          <div className="logo-container">
            <MantasLogo />
          </div>

          <div>
            <label className="form-label required"> Username / Email </label>
            <input
              type="text"
              className="form-control font-medium"
              value={value}
              onChange={(e) => set_value(e.target.value)}
            />
          </div>

          <div className="password-container">
            
          </div>

          <button
            type="submit"
            className="btn background-primary text-white w-100 login-btn d-flex align-items-center justify-content-center"
            disabled={
              value === "" ||
              is_saving
            }
          >
            {is_saving ? <div className="spinner-border"></div> : 'Send reset password mail'}
          </button>

          <div className="d-flex justify-content-center">
            <Link to='/login' className="link">
              Back to login
            </Link>
          </div>
        </form>
      </div>
    </div>
  )
}

export default ForgotPassword;