import { useState } from "react";
import Wrapper from "../Components/Wrapper";
import PasswordInput from "../Components/PasswordInput";
import AuthService from "../services/auth";

const blank_details_obj = {
  current_password : '',
  new_password : '',
  confirm_password : ''
}
const Settings = ({
  showSnackbar
}) => {

  const [details, set_details] = useState(blank_details_obj);
  const [is_saving, set_is_saving] = useState(false);

  const handleChangeDetails = (e) => {
    set_details({
      ...details,
      [e.target.name]: e.target.value
    })
  }

  const handleClickSave = async () => {

    if(details.new_password === details.confirm_password){
      set_is_saving(true);
      const [res, error] = await AuthService.updatePassword({
        current_password : details.current_password,
        new_password : details.new_password
      });
      set_is_saving(false);
  
      if (error) {
        showSnackbar('Error while updating user !', 'danger')
      } else {
        if(res.data.success){
          showSnackbar('Password updated successfully !', 'success');
          set_details(blank_details_obj);
        }else{
          showSnackbar('Invalid current password', 'danger')
        }
      }
    }else{
      showSnackbar('New password and confirm password does not match', 'warning')
    }
  }

  return (
    <Wrapper
      container_class='add-items-form'
      showSnackbar={showSnackbar}
    >

      <div className="paper-title font-semibold">
        Settings
      </div>
      <div className="input-row bg-white shadow-sm rounded-3">

        <div className="full-width section-title d-flex align-items-center">
          Update Password
        </div>
        <div>
          <label className="form-label"> Current Password </label>
          <PasswordInput
            name="current_password"
            value={details.current_password}
            onChange={handleChangeDetails}
          />
        </div>

        <div>
          <label className="form-label"> New Password </label>
          <PasswordInput
            name="new_password"
            value={details.new_password}
            onChange={handleChangeDetails}
          />
        </div>

        <div>
          <label className="form-label"> Confirm New Password </label>
          <PasswordInput
            name="confirm_password"
            value={details.confirm_password}
            onChange={handleChangeDetails}
          />
        </div>

        <div className="d-flex full-width justify-content-end">
          <button
            className="btn background-primary text-white"
            onClick={handleClickSave}
            disabled={
              !Boolean(details.current_password) ||
              !Boolean(details.new_password) ||
              !Boolean(details.confirm_password) ||
              is_saving
            }
          >
            {is_saving ? "Saving . . ." : "Save"}
          </button>
        </div>
      </div>
    </Wrapper>
  )
}

export default Settings;