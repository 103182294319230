import { useEffect, useState } from "react";
import AuthService from "../services/auth";
import { useParams } from "react-router-dom";
import { ReactComponent as MantasLogo } from "../assets/images/logo.svg";
import PasswordInput from "../Components/PasswordInput";

const SCREENS = {
  "ERROR": "ERROR",
  "FORM": "FORM"
}

const ResetPassword = ({ showSnackbar }) => {

  const [password, set_password] = useState("");
  const [is_saving, set_is_saving] = useState(false);
  const [is_loading, set_is_loading] = useState(true);
  const [screen, set_screen] = useState('');

  const params = useParams();
  const token = params.token;

  const handleSubmit = async (e) => {
    e.preventDefault();
    set_is_saving(true);
    const [res] = await AuthService.resetPassword({
      password : password,
      token : token
    });
    set_is_saving(false);

    if (res && res.data && res.data.success) {
      showSnackbar("Password reset successfully !", 'success')
    } else {
      showSnackbar("Error while resetting password !", 'danger')
    }
  }

  useEffect(() => {
    checkLinkStatus()
    // eslint-disable-next-line
  }, [])

  const checkLinkStatus = async () => {
    // eslint-disable-next-line
    const [res] = await AuthService.checkResetLinkStatus({
      token: token
    });

    let screen_to_show = SCREENS.ERROR;

    if (
      res?.data?.data?.active
    ) {
      screen_to_show = SCREENS.FORM
    }

    set_is_loading(false);
    set_screen(screen_to_show);
  }

  return (
    <div className="login-container min-vh-100 d-flex align-items-center justify-content-center">
      <div className="position-relative login-form-container overflow-hidden" style={{ minHeight: '200px' }}>
        {
          is_loading ?
            <div className="loader-container d-flex align-items-center justify-content-center">
              <div className="spinner-border"></div>
            </div> :
            <>
              {
                screen === SCREENS.FORM ?
                  <form
                    className="login-form"
                    onSubmit={handleSubmit}
                  >
                    <div className="logo-container">
                      <MantasLogo />
                    </div>

                    <div className="password-container mt-0">
                      <label className="form-label required"> New Password </label>
                      <PasswordInput
                        value={password}
                        onChange={(e) => set_password(e.target.value)}
                      />
                    </div>

                    <button
                      type="submit"
                      className="btn background-primary text-white w-100 login-btn d-flex align-items-center justify-content-center"
                      disabled={
                        password === "" ||
                        is_saving
                      }
                    >
                      {is_saving ? <div className="spinner-border"></div> : 'Reset password'}
                    </button>
                  </form> :
                  <div className="login-form">
                    <div className="logo-container">
                      <MantasLogo />
                    </div>

                    <div className="title">
                      Invalid Link !
                    </div>
                    <div className="description">
                      This link is expired or already used
                    </div>
                  </div>

              }
            </>

        }
      </div>
    </div>
  )
}

export default ResetPassword;