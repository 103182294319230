import { useNavigate } from "react-router-dom";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { useEffect } from "react";
import axios from "axios";
import AuthService from "../services/auth";

const Wrapper = ({
  children,
  container_class,
  show_header_search = false,
  showSnackbar
}) => {

  const container_classes = ['flex-grow-1', 'site-container'];

  if(container_class){
    container_classes.push(container_class)
  }

  const navigate = useNavigate();

  useEffect(() => {

    AuthService.checkLogin();
    
    if(!localStorage.getItem("ms_mvp_token")){
      navigate('/login')
    }
    // eslint-disable-next-line
  }, []);

  axios.interceptors.request.use(request => {
    const request_url = request.url;

    if(
      !(
        request_url.includes('/reset-password') ||
        request_url.includes('/check-reset-link-status')
      )
    ){
      const token = localStorage.getItem("ms_mvp_token");
      request.headers["Authorization"] = token;
    }
    return request;
  })

  axios.interceptors.response.use((response) => {
    return response
  }, (error) => {

    if (error && error.response && error.response.status === 401) {
      showSnackbar("Session expired ! Please login again", 'danger')
      setTimeout(() => {
        AuthService.logout();
        navigate("/login");
      }, 3500)
    }
    
    return Promise.reject(error)
  })

  return (
    <>
      <Header show_header_search = {show_header_search}/>
      <div className="d-flex site-wrapper">
        <Sidebar />
        <div className={container_classes.join(' ')}>
          {children}
        </div>
      </div>
    </>
  )
}

export default Wrapper;