import { NavLink } from 'react-router-dom';
import { ReactComponent as Dashboard } from '../assets/icons/dashboard.svg';
import { ReactComponent as Binoculars } from '../assets/icons/binoculars.svg';
import { ReactComponent as Office } from '../assets/icons/office.svg';
import { ReactComponent as LegalDocument } from '../assets/icons/legal-document.svg';
import { ReactComponent as Bill } from '../assets/icons/bill.svg';
import { ReactComponent as Database } from '../assets/icons/database.svg';
import { ReactComponent as SourceCode } from '../assets/icons/source-code.svg';
import { ReactComponent as Settings } from '../assets/icons/settings.svg';
import { ReactComponent as Buildings } from '../assets/icons/buildings.svg';
import { ReactComponent as Users } from '../assets/icons/users.svg';

const LINKS = [
  {
    title : 'Dashboard',
    path : '/',
    icon : <Dashboard className='icon' />
  },
  {
    title : 'Overview',
    icon : <Binoculars className='icon' />
  },
  {
    title : 'Accounts',
    path : '/accounts',
    icon : <Office className='icon' />
  },
  {
    title : 'Policies',
    path : '/policies',
    icon : <LegalDocument className='icon' />
  },
  {
    title : 'Agencies',
    icon : <Buildings className='icon' />
  },
  {
    title : 'Billing',
    path : '/bills',
    icon : <Bill className='icon' />
  },
  {
    title : 'Monitors',
    icon : <Database className='icon' />
  },
  {
    title : 'Documentation',
    icon : <SourceCode className='icon' />
  },
  {
    title : 'Settings',
    path : '/settings',
    icon : <Settings className='icon' />
  },
  {
    path : '/users',
    title : 'Users',
    icon : <Users className='icon' />
  }
]

const Sidebar = () => {
  return(
    <div className="sidebar flex-shrink-0 min-h-100">
      <div className="inner">
        {
          LINKS.map((link, ind) => {
            
            if(link.path){
              return(
                <NavLink
                  to={link.path}
                  className='d-flex align-items-center sidebar-link font-medium'
                  key={`sidebar-link-${ind}`}
                >
                  {link.icon} {link.title}
                </NavLink>
              )
            }else{
              return(
                <div
                  className='d-flex align-items-center sidebar-link font-medium'
                  key={`sidebar-link-${ind}`}
                >
                  {link.icon} {link.title}
                </div>
              )
            }
          })
        }
      </div>
    </div>
  )
}

export default Sidebar;