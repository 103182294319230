import axios from "axios";
import { api_base_url } from "./config";

const auth = {};

auth.login = async (username, password) => {

  const url = `${api_base_url}/auth/login`

  try {
    const res = await axios({
      url: url,
      method: "POST",
      data: {
        username: username,
        password: password
      }
    });

    return [res, null]
  } catch (error) {
    return [null, error]
  }
}

auth.logout = () => {
  localStorage.removeItem("ms_mvp_token")
}

auth.checkLogin = async () => {
  const url = `${api_base_url}/auth/check-login`

  try {
    const res = await axios({
      url: url,
      method: "GET"
    });

    return [res, null]
  } catch (error) {
    return [null, error]
  }
}

auth.addUser = async ({
  name,
  email,
  username,
  password
}) => {
  const url = `${api_base_url}/auth/add-user`

  try {
    const res = await axios({
      url: url,
      method: "POST",
      data: {
        name : name,
        email : email,
        username: username,
        password: password
      }
    });

    return [res, null]
  } catch (error) {
    return [null, error]
  }
}

auth.getUsers = async (skip, limit) => {
  const url = `${api_base_url}/user/get-users`

  try{
    const res = await axios({
      url : url,
      params : {
        skip : skip,
        limit : limit
      }
    });

    return [res, null]
  }catch(error){
    return [null, error]
  }
}

auth.updateUser = async (id, data) => {
  const url = `${api_base_url}/user/update/${id}`

  try{
    const res = await axios({
      url : url,
      method : 'PUT',
      data : {
        data : data
      }
    });

    return [res, null]
  }catch(error){
    return [null, error]
  }
}

auth.updatePassword = async ({
  current_password,
  new_password
}) => {
  const url = `${api_base_url}/auth/update-password`

  try{
    const res = await axios({
      url : url,
      method : 'POST',
      data : {
        current_password : current_password,
        new_password : new_password
      }
    });

    return [res, null]
  }catch(error){
    return [null, error]
  }
}

auth.sendResetPasswordMail = async ({
  value
}) => {
  const url = `${api_base_url}/auth/send-reset-password-mail`

  try{
    const res = await axios({
      url : url,
      method : 'POST',
      data : {
        value : value
      }
    });

    return [res, null]
  }catch(error){
    return [null, error]
  }
}

auth.resetPassword = async ({
  password,
  token
}) => {
  const url = `${api_base_url}/auth/reset-password`

  try{
    const res = await axios({
      url : url,
      method : 'POST',
      headers : {
        'Authorization' : token
      },
      data : {
        password
      }
    });

    return [res, null]
  }catch(error){
    return [null, error]
  }
}

auth.checkResetLinkStatus = async ({
  token
}) => {
  const url = `${api_base_url}/auth/check-reset-link-status`

  try{
    const res = await axios({
      url : url,
      method : 'GET',
      headers : {
        'Authorization' : token
      }
    });

    return [res, null]
  }catch(error){
    return [null, error]
  }
}

auth.verifyOTP = async ({
  otp_token,
  otp
}) => {
  const url = `${api_base_url}/auth/verify-otp`

  try{
    const res = await axios({
      url : url,
      method : 'POST',
      data : {
        otp_token : otp_token,
        otp : otp
      }
    });

    return [res, null]
  }catch(error){
    return [null, error]
  }
}

auth.resendOTP = async ({
  otp_token
}) => {
  const url = `${api_base_url}/auth/resend-otp`

  try{
    const res = await axios({
      url : url,
      method : 'POST',
      data : {
        otp_token : otp_token
      }
    });

    return [res, null]
  }catch(error){
    return [null, error]
  }
}

export default auth;