import { useState } from "react";
import AuthService from "../services/auth";
import { useNavigate } from "react-router-dom";
import { ReactComponent as MantasLogo } from "../assets/images/logo.svg";
import OTPInput from "react-otp-input";

const VerifyOTP = ({ showSnackbar }) => {

  const [otp, set_otp] = useState("");
  const [is_saving, set_is_saving] = useState(false);
  const [is_sending_otp, set_is_sending_otp] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    set_is_saving(true);
    const [res] = await AuthService.verifyOTP({
      otp_token : localStorage.getItem("ms_mvp_otp_token"),
      otp : otp
    });
    set_is_saving(false);

    if (res?.data?.success) {
      localStorage.removeItem("ms_mvp_otp_token")
      localStorage.setItem("ms_mvp_token", res.data.data.token);
      localStorage.setItem("ms_mvp_user", JSON.stringify(res.data.data.user));
      navigate('/')
    } else {
      showSnackbar("Invalid OTP !", 'danger')
    }
  }

  const resendOTP = async () => {
    set_is_sending_otp(true);
    const [res] = await AuthService.resendOTP({
      otp_token : localStorage.getItem("ms_mvp_otp_token"),
      otp : otp
    });
    set_is_sending_otp(false);

    if (res?.data?.success) {
      localStorage.setItem("ms_mvp_otp_token", res.data.data.otp_token);
      set_otp('');
      showSnackbar("OTP sent successfully !", 'success');
    } else {
      showSnackbar("Error while sending OTP !", 'danger');
    }
  }

  return (
    <div className="login-container min-vh-100 d-flex align-items-center justify-content-center">
      <div className="login-form-container d-flex align-items-center">
        <form
          className="login-form"
          onSubmit={handleSubmit}
        >
          <div className="logo-container">
            <MantasLogo />
          </div>

          <div className="password-container">
            <label className="form-label required"> Enter OTP </label>

            <OTPInput
              value={otp}
              onChange={(otp_value) => set_otp(otp_value)}
              numInputs={6}
              renderInput={(props) => <input className="form-control font-medium" {...props} />}
              containerStyle="otp-input-container"
            />
          </div>

          <button
            type="submit"
            className="btn background-primary text-white w-100 login-btn d-flex align-items-center justify-content-center"
            disabled={
              otp.length !== 6 ||
              is_saving
            }
          >
            {is_saving ? <div className="spinner-border"></div> : 'Verify OTP'}
          </button>

          <button
            type="button"
            className="btn btn-outline-primary w-100 login-btn d-flex align-items-center justify-content-center"
            onClick={resendOTP}
            disabled={is_sending_otp}
          >
            {is_sending_otp ? <div className="spinner-border"></div> : 'Resend OTP'}
          </button>
        </form>
      </div>
    </div>
  )
}

export default VerifyOTP;