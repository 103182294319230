import { useState } from "react";
import Wrapper from "../Components/Wrapper";
import { useNavigate } from "react-router-dom";
import PasswordInput from "../Components/PasswordInput";
import AuthService from "../services/auth";

const blank_details_obj = {
  name: '',
  email: '',
  password: '',
  username: ''
}
const AddUser = ({
  showSnackbar
}) => {

  const [details, set_details] = useState(blank_details_obj);
  const [is_saving, set_is_saving] = useState(false);

  const navigate = useNavigate();

  const handleChangeDetails = (e) => {
    set_details({
      ...details,
      [e.target.name]: e.target.value
    })
  }

  const navigateToUsersPage = () => {
    navigate('/users');
  }

  const handleClickSave = async () => {
    set_is_saving(true);
    // eslint-disable-next-line
    const [res, error] = await AuthService.addUser(details);
    set_is_saving(false);

    if (error) {
      showSnackbar('Error while creating user !', 'danger')
    } else {
      if(res.data.success){
        showSnackbar('User created successfully !', 'success');
        set_details(blank_details_obj);
        setTimeout(() => {
          navigateToUsersPage();
        }, 1000);
      }else{
        showSnackbar(res.data.error, 'danger')
      }
    }
  }

  return (
    <Wrapper
      container_class='add-items-form'
      showSnackbar={showSnackbar}
    >

      <div className="paper-title font-semibold">
        Add new user
      </div>
      <div className="input-row bg-white shadow-sm rounded-3">
        <div>
          <label className="form-label"> Name </label>
          <input
            type="text"
            className="form-control font-medium"
            name="name"
            value={details.name}
            onChange={handleChangeDetails}
          />
        </div>

        <div>
          <label className="form-label"> Email </label>
          <input
            type="text"
            className="form-control font-medium"
            name="email"
            value={details.email}
            onChange={handleChangeDetails}
          />
        </div>

        <div>
          <label className="form-label"> Username </label>
          <input
            type="text"
            className="form-control font-medium"
            name="username"
            value={details.username}
            onChange={handleChangeDetails}
          />
        </div>

        <div>
          <label className="form-label"> Password </label>
          <PasswordInput
            name="password"
            value={details.password}
            onChange={handleChangeDetails}
          />
        </div>

        <div className="d-flex full-width justify-content-end">
          <button
            className="btn background-primary text-white"
            onClick={handleClickSave}
            disabled={
              !Boolean(details.name) ||
              !Boolean(details.email) ||
              !Boolean(details.password) ||
              !Boolean(details.username) ||
              is_saving
            }
          >
            {is_saving ? "Saving . . ." : "Save"}
          </button>
        </div>
      </div>
    </Wrapper>
  )
}

export default AddUser;