import { useState } from "react";
import { ReactComponent as Eye } from '../assets/icons/eye.svg';
import { ReactComponent as EyeClose } from '../assets/icons/eye-close.svg';

const PasswordInput = ({
  ...props
}) => {

  const [show_password, set_show_password] = useState(false);

  return (
    <div class="input-group mb-3">
      <input
        type={show_password ? 'text' : 'password'}
        class="form-control font-medium"
        {...props}
      />
      <span
        class="input-group-text cursor-pointer"
        onClick={() => set_show_password(!show_password)}
      >
        {
          show_password ? <Eye /> : <EyeClose />
        }
      </span>
    </div>
  )
}

export default PasswordInput;