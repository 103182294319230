import { useEffect, useState } from "react";
import Wrapper from "../Components/Wrapper";
import { ReactComponent as VerticalDots } from "../assets/icons/vertical-dots.svg";
import { ReactComponent as CaretDown } from "../assets/icons/caret-down.svg";
import ApexCharts from "apexcharts";

const RECENT_SERVICES = [
  {
    title: 'ping4',
    url: 'app.mantas.ai',
    message: 'PING OK - Packet loss = 0%, RTA = 1.45 ms',
    status: 'OK',
    time: '21:21'
  },
  {
    title: 'dns app.mantas.ai',
    url: 'app.mantas.ai',
    message: 'DNS OK: 0.032 seconds response time . nws.netways.de returns 185.233.188.221, 185.233.189.100',
    status: 'OK',
    time: '21:21'
  },
  {
    title: 'http app.mantas.ai',
    url: 'app.mantas.ai',
    message: 'HTTP OK: HTTP/1.1 200 OK - 538341 bytes in 0.042 second response time',
    status: 'OK',
    time: '21:21'
  },
  {
    title: 'smtp Mailrelay',
    url: 'app.mantas.ai',
    message: 'SMTP OK - 0.046 sec.response time',
    status: 'OK',
    time: '21:13'
  }
]
const Dashboard = ({
  showSnackbar
}) => {

  const [search, set_search] = useState('');

  useEffect(() => {
    
    // Causes of Downtime chart

    const radialBarChartOptions = {
      series: [76, 67, 61, 90],
      chart: {
        height: 390,
        type: 'radialBar',
        fontFamily: '"Poppins", sans-serif',
        animations: {
          enabled: false
        },
      },
      plotOptions: {
        radialBar: {
          offsetY: 0,
          startAngle: 0,
          endAngle: 270,
          hollow: {
            margin: 5,
            size: '50%',
            background: 'transparent',
            image: undefined,
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              show: false,
            }
          },
          barLabels: {
            enabled: true,
            useSeriesColors: false,
            offsetX: -8,
            fontSize: '14px',
          },
        }
      },
      states: {
        hover: {
          filter: {
            type: 'none',
            value: 0,
          }
        }
      },
      colors: ['#CBDBF4', '#2A2AFF', '#000000', '#CBDBF4'],
      labels: ['Connectivity', 'System overload', 'Physical Infrastructure', 'Cloud Outage'],
      responsive: [{
        breakpoint: 480,
        options: {
          legend: {
            show: false
          }
        }
      }]
    }

    const radial_bar_chart = new ApexCharts(document.getElementById("radial-bar-chart-container"), radialBarChartOptions);
    radial_bar_chart.render();

    // Host pings chart

    const host_pings_chart_data = [
      {
        x : '14:00',
        y : null
      },
      {
        x : '14:10',
        y : null
      },
      {
        x : '14:20',
        y : 1.5
      },
      {
        x : '14:30',
        y : 1.25
      },
      {
        x : '14:40',
        y : 1.4
      },
      {
        x : '14:50',
        y : 1
      },
      {
        x : '15:00',
        y : 1.85
      },
      {
        x : '15:10',
        y : 1.5
      },
      {
        x : '15:20',
        y : null
      },
      {
        x : '15:30',
        y : null
      }
    ]
    const host_pings_chart_options = {
      series: [
        {
          name: '',
          type: 'column',
          data: host_pings_chart_data
        }, {
          name: '',
          type: 'line',
          data: host_pings_chart_data
        }
      ],
      chart: {
        height: 250,
        type: 'line',
        animations: {
          enabled: false
        },
        toolbar : {
          show : false
        }
      },
      stroke: {
        width: [0, 2],
        colors : ['#2A2AFF']
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        show: true,
        borderColor: '#2A2AFF',
        xaxis: {
          lines: {
            show: true
          }
        },
        yaxis: {
          lines: {
            show: true
          }
        }
      },
      legend : {
        show : false
      },
      tooltip : {
        enabled : false
      },
      plotOptions : {
        bar : {
          columnWidth : '15px',
        }
      },
      fill : {
        opacity : 1,
        colors: ['#2A2AFF']
      },
      states: {
        hover: {
          filter: {
            type: 'none',
            value: 0,
          }
        },
        active: {
          filter: {
            type: 'none',
            value: 0,
          }
        }
      }
    }
    const host_pings_chart = new ApexCharts(document.getElementById("host-pings-chart-container"), host_pings_chart_options);
    host_pings_chart.render()

  }, []);

  return (
    <Wrapper
      container_class={'dashboard-container'}
      show_header_search={true}
      showSnackbar = {showSnackbar}
    >
      <div className="top-filter-row">
        <div>
          <div className="input-group">
            <input
              type="text"
              className="form-control no-box-shadow-input font-semibold"
              value={search}
              onChange={(e) => set_search(e.target.value)}
              placeholder="Search by Customer ID, Company Name, or url (E.g. http://)"
            />
            <div className="input-group-text background-primary d-flex align-items-center justify-content-center" style={{ width: '45px' }}>
              <VerticalDots />
            </div>
          </div>
        </div>
        <div className="dropdown-row">

          <div className="dropdown d-flex align-items-center justify-content-between shadow-sm">
            <div className="d-flex align-items-center font-semibold">
              Country

              <span className="count d-flex align-items-center justify-content-center"> 1 </span>
            </div>
            <CaretDown className="caret-icon" />
          </div>

          <div className="dropdown d-flex align-items-center justify-content-between shadow-sm">
            <div className="d-flex align-items-center font-semibold">
              Company
            </div>
            <CaretDown className="caret-icon" />
          </div>

          <div className="dropdown d-flex align-items-center justify-content-between shadow-sm">
            <div className="d-flex align-items-center font-semibold">
              Policy Type

              <span className="count d-flex align-items-center justify-content-center"> 2 </span>
            </div>
            <CaretDown className="caret-icon" />
          </div>

        </div>
      </div>

      <div className="insights-row">
        <div className="insight-box shadow-sm bg-white text-center rounded-3">
          <div className="font-medium">
            Number of incidents
          </div>
          <div className="number font-semibold">
            0
          </div>
          <div className="label font-semibold">
            issues
          </div>
        </div>

        <div className="insight-box shadow-sm bg-white text-center rounded-3">
          <div className="font-medium">
            Last incident
          </div>
          <div className="number font-semibold">
            822
          </div>
          <div className="label font-semibold">
            days ago
          </div>
        </div>

        <div className="insight-box shadow-sm bg-white text-center rounded-3">
          <div className="font-medium">
            Number of Hosts
          </div>
          <div className="number font-semibold">
            2
          </div>
        </div>

        <div className="insight-box shadow-sm bg-white text-center rounded-3">
          <div className="font-medium">
            Minor Outages
          </div>
          <div className="number font-semibold">
            2
          </div>
        </div>

      </div>

      <div className="charts-container bg-white shadow-sm rounded-3">
        <div>
          <div className="paper-title font-semibold">
            Causes of Downtime
          </div>

          <div id="radial-bar-chart-container">
          </div>
        </div>

        <div>
          <div className="paper-title font-semibold">
            Host Pings over time
          </div>

          <div className="d-flex justify-content-end">
            <div className="shadow-sm font-medium rounded-1 time-filter">
              Show data : <span> 1 hour </span>
            </div>
          </div>
          <div id="host-pings-chart-container">
          </div>

          <div className="font-medium text-center" style={{ fontSize : '12px' }}>
            Round trip time (ms)
          </div>
        </div>
      </div>

      <div className="details-row bg-white shadow-sm rounded-3">
        <div>
          <div className="paper-title font-semibold">
            Recently Recovered Services
          </div>

          {
            RECENT_SERVICES.map((service, ind) => {
              return (
                <div
                  key={`service-${ind}`}
                  className="service-container d-flex align-items-center source-code-pro"
                >
                  <div className="flex-shrink-0 text-center status-container">
                    <div>
                      {service.status}
                    </div>
                    <div>
                      {service.time}
                    </div>
                  </div>

                  <div className="flex-grow-1">
                    <div className="title d-flex font-semibold">
                      <div>
                        {service.title}
                      </div>
                      <div>
                        {service.url}
                      </div>
                    </div>

                    <div className="font-light description">
                      {service.message}
                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>

        <div>
          <div className="paper-title font-semibold">
            Host Problems
          </div>

          <div>
            No hosts found matching the filter
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default Dashboard;